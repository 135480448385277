<template>
  <div class="container">
    <h2 class="text-center my-3">
      {{ $t("enrollment.success.title") }}
    </h2>
    <p>
      {{ $t("enrollment.success.message") }}
    </p>
    <el-card class="mb-3">
      <h5>{{ $t("enrollment.success.Next steps:") }}</h5>
      <div>
        <strong
          >1.
          {{
            $t("enrollment.success.Check for Your Confirmation Email")
          }}</strong
        >
      </div>
      <ul>
        <li>
          <span
            v-html="
              $t('enrollment.success.receive', {
                emails: emails ? `<b>${emails.join(', ')}</b>` : ''
              })
            "
          >
          </span>
        </li>
      </ul>
      <div>
        <strong>2. {{ $t("enrollment.success.Contact Us") }}</strong>
      </div>
      <ul>
        <li>
          {{
            $t(
              "enrollment.success.If you use Line, please add us as friends to facilitate future communication:"
            )
          }}
          <el-popover placement="top" trigger="hover">
            <a href="https://page.line.me/ivyway" slot="reference">
              <b>@ivyway</b>
            </a>
            <a target="_blank" href="https://page.line.me/ivyway">
              <img width="140" height="140" :src="lineQRcode" />
            </a>
          </el-popover>
          {{ $t("enrollment.success.(theres an @ sign in the front)") }}
        </li>
        <li>
          {{ $t("enrollment.success.You can also add us on WeChat (") }}
          <el-popover placement="top" trigger="hover">
            <b style="cursor: pointer;" slot="reference">ivywaysat</b>
            <a target="_blank" href="https://page.line.me/ivyway">
              <img width="140" height="140" :src="wechatQRcode2" />
            </a>
          </el-popover>
          {{ $t("enrollment.success.instead") }}
        </li>
      </ul>
      <div>
        <strong>3. {{ $t("enrollment.success.Wait for Our Reply") }}</strong>
      </div>
      <ul>
        <li>
          {{ $t("enrollment.success.journey") }}
        </li>
      </ul>
      <!-- <template v-if="hasPrivate">
        <div>
          <strong
            >4.
            {{
              $t(
                "enrollment.success.Tell Us More about Your Situation And Needs"
              )
            }}</strong
          >
        </div>
        <ul>
          <li>
            {{ $t("enrollment.success.instructor") }}
          </li>
        </ul>
        <p class="text-center">
          <el-button type="success">
            {{ $t("enrollment.success.Start") }}
          </el-button>
        </p>
      </template> -->
      <template v-if="hasCounseling">
        <div>
          <strong
            >4.
            {{
              $t(
                "enrollment.success.Tell Us More about Your Situation And Needs"
              )
            }}</strong
          >
        </div>
        <div style="padding-left: 20px;">
          <el-alert
            class="mt-4 mb-4"
            title="為了給您提供更好的服務，希望您能抽出幾分鐘時間，將您的感受和建議告訴我們，我們非常重視每位用戶的寶貴意見，期待您的參與！"
            type="info"
            :closable="false"
            show-icon
            effect="dark"
          >
          </el-alert>
          <div
            v-for="(student,
            index) in enrollmentForm.enrolled_session_students"
            :key="index"
          >
            <Heading :content="user.displayName(student.first_name, student.last_name)" heading="h5"></Heading>
            <hr />
            <Survey
              v-if="student.student_profile"
              :student="student.student_profile"
            ></Survey>
          </div>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";

import enrollmentMixin from "@/mixins/enrollment";
import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";
import Survey from "@/views/survey/survey";

export default {
  components: { Survey },

  mixins: [enrollmentMixin],

  props: [],
  data() {
    return {
      enrollmentForm: null,
      lineQRcode: require("@/assets/qrcode/line_QR.jpg"),
      wechatQRcode1: require("@/assets/qrcode/ivywaychina_QR.webp"),
      wechatQRcode2: require("@/assets/qrcode/TW_WeChat_QR.webp"),
      wechatQRcode3: require("@/assets/qrcode/ivywayedu_QR.webp")
    };
  },
  computed: {
    user() {
      return user;
    },
    enrollmentId() {
      let enrollmentId = null;
      if (this.$route.query.enrollment_id) {
        enrollmentId = parseInt(this.$route.query.enrollment_id);
      }
      return enrollmentId;
    },
    hasCourses() {
      return true;
    },
    hasPrivate() {
      return false;
    },
    hasCounseling() {
      let isCounseling = false;
      this.enrollmentForm.interested_course_sessions.forEach(item => {
        if (item.id === 4) {
          isCounseling = true;
        }
      });
      return isCounseling;
    },
    emails() {
      let emails = [];
      if (this.enrollmentForm) {
        if (this.enrollmentForm.parent_user.email) {
          emails.push(this.enrollmentForm.parent_user.email);
        }
        this.enrollmentForm.enrolled_session_students.forEach(student => {
          emails.push(student.email);
        });
      }
      return emails;
    }
  },
  destroyed() {
    // this.keepEnrollmentForm({});
  },
  watch: {},

  async mounted() {
    const res = await enrollmentApi.getEnrollmentInfo(this.enrollmentId);
    this.enrollmentForm = { ...res.enrollment_form };
  },

  methods: {}
};
</script>

<style scoped>
ul {
  margin-left: 20px;
}
.input-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 5px;
  background-size: cover;
  background-color: #42a16a;
}
.icon-qr {
  cursor: pointer;
  mask: url("/assets/svg/qrcode.svg") no-repeat center;
}
</style>
